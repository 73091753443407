/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
.firebase-emulator-warning {
  display: none !important;
}

.select-none {
  user-select: none;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.margin-x-auto {
  margin-inline-start: auto;
  margin-inline-end: auto;
}

.rounded-borders {
  border-radius: 8px;
}

.no-box-shadow {
  --box-shadow: none;
  box-shadow: none;
}

ion-list-header ion-label h1,
ion-list-header ion-label h2,
ion-list-header ion-label h3,
ion-list-header ion-label h4,
ion-list-header ion-label h5 {
  font-weight: 800 !important;
  font-size: 16px !important;
}
ion-list-header ion-label p {
  font-weight: 500 !important;
}

ion-title,
ion-label,
ion-breadcrumb,
ion-list-header,
ion-note[slot=helper] {
  user-select: none;
}

ion-button,
ion-segment-button {
  text-transform: none;
}

ion-button {
  --box-shadow: none;
}
ion-button ion-label {
  line-height: 1.42;
}

ion-button[fill=outline] {
  --border-width: 1px;
}

ion-card {
  --background: var(--ion-color-light);
  --color: var(--ion-color-light-contrast);
  box-shadow: none;
  border-radius: 6px;
}
ion-card > ion-item {
  --background: --background;
  --color: --color;
}

ion-header ion-toolbar ion-title.centered ion-button.branding {
  display: block;
  margin: 4px auto;
}
ion-header ion-toolbar ion-buttons ion-button.branding,
ion-header ion-toolbar ion-title ion-button.branding {
  --app-padding: 2px;
  --app-size: 32px;
  --app-border-radius: 6px;
  --padding-start: var(--app-padding);
  --padding-end: var(--app-padding);
  --padding-top: var(--app-padding);
  --padding-bottom: var(--app-padding);
  --border-radius: var(--app-border-radius);
  min-width: var(--app-size);
  width: fit-content;
  height: var(--app-size);
}
ion-header ion-toolbar ion-buttons ion-button.branding ion-thumbnail,
ion-header ion-toolbar ion-title ion-button.branding ion-thumbnail {
  --size: calc(var(--app-size) - 2 * var(--app-padding));
  --border-radius: var(--app-border-radius);
  --box-shadow: var(--app-shadow);
}
ion-header ion-toolbar ion-buttons ion-button.branding ion-thumbnail[slot=start],
ion-header ion-toolbar ion-title ion-button.branding ion-thumbnail[slot=start] {
  margin-inline-end: 8px;
}
ion-header ion-toolbar ion-buttons ion-button.branding ion-label,
ion-header ion-toolbar ion-title ion-button.branding ion-label {
  font-size: calc(var(--app-size) * 0.5);
}

ion-popover {
  --width: 350px;
}

ion-grid.page-split-container > ion-row > ion-col > .header {
  padding-inline-start: var(--ion-padding, 16px);
  padding-inline-end: var(--ion-padding, 16px);
}
ion-grid.page-split-container > ion-row > ion-col > .header ion-thumbnail {
  --size: 56px;
  --border-radius: 6px;
  width: 56px;
  min-width: 56px;
  height: 56px;
  min-height: 56px;
  margin-inline-end: 12px;
}
ion-grid.page-split-container > ion-row > ion-col > .header p {
  margin: -12px 0 0 0;
  font-weight: 500;
}
ion-grid.page-split-container > ion-row > ion-col > .content {
  padding-inline-start: var(--ion-padding, 16px);
  padding-inline-end: var(--ion-padding, 16px);
  padding-top: var(--ion-padding, 16px);
  padding-bottom: var(--ion-padding, 16px);
}
ion-grid.page-split-container > ion-row > ion-col > ion-button {
  align-self: flex-start;
}
ion-grid.page-split-container > ion-row > ion-col > ion-button[expand=block] {
  width: 100%;
}

@media screen and (min-width: 768px) {
  ion-grid.page-split-container {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  ion-grid.page-split-container > ion-row > ion-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  ion-grid.page-split-container > ion-row > ion-col:first-of-type {
    max-height: fit-content;
    min-height: calc(100vh - 70px);
    position: sticky;
    top: 0;
  }
}
ion-modal.app-connect {
  --width: 300px;
  --height: 300px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
}

@media screen and (min-width: 768px) {
  ion-modal {
    --border-radius: 12px;
  }
}
ion-popover {
  --width: 242px;
}

@media screen and (max-width: 768px) {
  ion-popover.md-above-btn-two-items {
    --offset-y: -164px;
  }
  ion-popover.md-above-btn-one-item {
    --offset-y: -80px;
  }
}
app-pass-page ion-toolbar ion-title ion-thumbnail,
app-member-page ion-toolbar ion-title ion-thumbnail,
app-event-page ion-toolbar ion-title ion-thumbnail,
app-presence-page ion-toolbar ion-title ion-thumbnail {
  --border-radius: 4px;
  --size: 24px;
  margin-inline-end: 12px;
  float: left;
}
app-pass-page ion-toolbar ion-title h3,
app-member-page ion-toolbar ion-title h3,
app-event-page ion-toolbar ion-title h3,
app-presence-page ion-toolbar ion-title h3 {
  font-size: 18px;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

app-home-page,
app-authenticate-page,
app-complete-link-auth-page,
app-event-page,
app-home-page,
app-member-page,
app-not-found-page,
app-pass-page,
app-presence-page {
  --ion-toolbar-background: var(--ion-background-color);
}

.picker-bottom {
  --offset-y: -32px;
}

ion-item.table-header ion-label ion-grid,
ion-item.table-row ion-label ion-grid,
ion-item.table-form ion-label ion-grid {
  padding: 0 0;
}
ion-item.table-header ion-label ion-grid ion-row ion-col,
ion-item.table-row ion-label ion-grid ion-row ion-col,
ion-item.table-form ion-label ion-grid ion-row ion-col {
  padding: 4px 4px;
  display: flex;
  align-items: center;
}
ion-item.table-header ion-label ion-grid ion-row ion-col h1,
ion-item.table-header ion-label ion-grid ion-row ion-col h2,
ion-item.table-header ion-label ion-grid ion-row ion-col h3,
ion-item.table-header ion-label ion-grid ion-row ion-col h4,
ion-item.table-header ion-label ion-grid ion-row ion-col h5,
ion-item.table-header ion-label ion-grid ion-row ion-col p,
ion-item.table-header ion-label ion-grid ion-row ion-col ion-button,
ion-item.table-row ion-label ion-grid ion-row ion-col h1,
ion-item.table-row ion-label ion-grid ion-row ion-col h2,
ion-item.table-row ion-label ion-grid ion-row ion-col h3,
ion-item.table-row ion-label ion-grid ion-row ion-col h4,
ion-item.table-row ion-label ion-grid ion-row ion-col h5,
ion-item.table-row ion-label ion-grid ion-row ion-col p,
ion-item.table-row ion-label ion-grid ion-row ion-col ion-button,
ion-item.table-form ion-label ion-grid ion-row ion-col h1,
ion-item.table-form ion-label ion-grid ion-row ion-col h2,
ion-item.table-form ion-label ion-grid ion-row ion-col h3,
ion-item.table-form ion-label ion-grid ion-row ion-col h4,
ion-item.table-form ion-label ion-grid ion-row ion-col h5,
ion-item.table-form ion-label ion-grid ion-row ion-col p,
ion-item.table-form ion-label ion-grid ion-row ion-col ion-button {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

ion-item.table-row:last-of-type {
  --border-width: 0;
  --inner-border-width: 0;
}

ion-item.table-form ion-label ion-grid > ion-row > ion-col {
  overflow: hidden;
}
ion-item.table-form ion-label ion-badge {
  margin-inline: 4px;
}
ion-item.table-form ion-label ion-button {
  max-width: 100%;
}
ion-item.table-form ion-label ion-select {
  max-width: 100%;
  width: 100%;
  margin: 0 0;
}
ion-item.table-form ion-label input {
  width: 100%;
  margin: 4px 4px;
  border-radius: 4px;
  border-width: 0px;
  padding: 4px 6px;
}