:root .text-xs {
  font-size: var(--app-fs-xs);
  line-height: var(--app-lh-xs);
}
:root .text-sm {
  font-size: var(--app-fs-sm);
  line-height: var(--app-lh-sm);
}
:root .text-md {
  font-size: var(--app-fs-md);
  line-height: var(--app-lh-md);
}
:root .text-lg {
  font-size: var(--app-fs-lg);
  line-height: var(--app-lh-lg);
}
:root .text-xl {
  font-size: var(--app-fs-xl);
  line-height: var(--app-lh-xl);
}
:root .text-2xl {
  font-size: var(--app-fs-2xl);
  line-height: var(--app-lh-2xl);
}
:root .text-3xl {
  font-size: var(--app-fs-3xl);
  line-height: var(--app-lh-3xl);
}
:root .text-4xl {
  font-size: var(--app-fs-4xl);
  line-height: var(--app-lh-4xl);
}
:root .italic {
  font-style: italic;
}
:root .font-thin {
  font-weight: 100;
}
:root .font-extralight {
  font-weight: 200;
}
:root .font-light {
  font-weight: 300;
}
:root .font-normal {
  font-weight: 400;
}
:root .font-medium {
  font-weight: 500;
}
:root .font-semibold {
  font-weight: 600;
}
:root .font-bold {
  font-weight: 700;
}
:root .font-extrabold {
  font-weight: 800;
}
:root .font-black {
  font-weight: 900;
}
:root .rounded-none {
  border-radius: 0px;
}
:root .rounded-sm {
  border-radius: 0.125rem; /* 2px */
}
:root .rounded {
  border-radius: 0.25rem; /* 4px */
}
:root .rounded-md {
  border-radius: 0.375rem; /* 6px */
}
:root .rounded-lg {
  border-radius: 0.5rem; /* 8px */
}
:root .rounded-xl {
  border-radius: 0.75rem; /* 12px */
}
:root .rounded-2xl {
  border-radius: 1rem; /* 16px */
}
:root .rounded-3xl {
  border-radius: 1.5rem; /* 24px */
}
:root .rounded-full {
  border-radius: 9999px;
}
:root .overflow-auto {
  overflow: auto;
}
:root .overflow-hidden {
  overflow: hidden;
}
:root .overflow-clip {
  overflow: clip;
}
:root .overflow-visible {
  overflow: visible;
}
:root .overflow-scroll {
  overflow: scroll;
}
:root .shadow-sm {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}
:root .shadow {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
}
:root .shadow-md {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
}
:root .shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
}
:root .shadow-xl {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1);
}
:root .shadow-2xl {
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}
:root .shadow-inner {
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.05);
}
:root .shadow-none {
  box-shadow: 0 0 rgba(0, 0, 0, 0);
}